import { useCallback, useEffect, useMemo, useState } from 'react';
import Dates from './dates';
import Times from './times';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { getCheckoutURL } from '@/utils/checkout';
import PhoneNumberCTA from '@/components/phoneNumberCTA';
import { areSameDayTimestamps, isMorning, parseDate } from '@/utils/datetime';
import { FormStep } from '@/store/createCheckoutSlice';
import { useInclinicStore, useTantoSvagoStore } from '@/store';
import { AvailabilitySlotsForSpecialityNoAuthQueryResult } from '@/graphql/types';
import { isTimeStampInSameDayOfLastAvailability } from '@/utils/reservation';
import { ELButton, ELLoader } from '@davincihealthcare/elty-design-system-react';
import { ROUTES } from '@/routes';
import { useIsClientSide } from '@/hooks/useIsClientSide';

type SlotAvailabilityResult = AvailabilitySlotsForSpecialityNoAuthQueryResult['availabilitySlotsForSpecialityNoAuth'];

export type BoardSlot = NonNullable<SlotAvailabilityResult>[number] & {
  timeText: string;
};

export type BoardSection = { label: string; slots: BoardSlot[] };
export type Board = BoardSection[];

type AvailabilityContainerProps = {
  workgroupId?: string | null;
  availabilitySlots: SlotAvailabilityResult;
  loading: boolean;
  disabled?: boolean;
  isInCard?: boolean;
  onAddToCart?: () => void;
  setSubmissionURLRef?: (s: string) => void;
  numberOfDays: number;
  loadAvailabilities: (startDate: number) => Promise<void>;
  setExternalSlot?: (s: BoardSlot) => void;
};

const AvailabilityContainer = ({
  workgroupId,
  availabilitySlots,
  loading,
  disabled = true,
  isInCard = false,
  onAddToCart,
  setSubmissionURLRef,
  numberOfDays,
  loadAvailabilities,
  setExternalSlot,
}: AvailabilityContainerProps) => {
  const router = useRouter();
  const [userSelectedStartTime, setUserSelectedStartTime] = useState<undefined | number>(undefined);
  const firstAvailableStartTime = availabilitySlots?.[0]?.start;
  const [selectedSlot, setSelectedSlot] = useState<BoardSlot | undefined>(undefined);
  const { updateCheckoutFormField } = useInclinicStore(state => state);
  const { apiHeaders } = useTantoSvagoStore();
  const selectedStartTime = useMemo(
    () => userSelectedStartTime ?? firstAvailableStartTime,
    [userSelectedStartTime, firstAvailableStartTime],
  );
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const isClientSide = useIsClientSide();

  const hasAvailabilities = availabilitySlots && availabilitySlots.length > 0;
  const isInvalidSelection = !isInCard && disabled;

  const submitUrl = (selectedSlot: BoardSlot, specialityId: string) =>
    getCheckoutURL({
      pathname: ROUTES.CHECKOUT_RECAP.pathname,
      ambulatoryId: workgroupId,
      startTime: selectedSlot.start,
      availabilitySlotId: selectedSlot.id,
      specialityId: specialityId,
    });

  const board = useMemo(() => {
    const board: Board = [
      { label: 'Mattino', slots: [] },
      { label: 'Pomeriggio', slots: [] },
    ];

    if (!selectedStartTime) return board;

    for (const slot of availabilitySlots ?? []) {
      if (
        // Doesn't have a start time
        !slot?.start ||
        // Already in the board
        board.some(v => v.slots.some(s => s.start === slot.start)) ||
        // Not the same day
        !areSameDayTimestamps(slot.start, selectedStartTime)
      )
        continue;
      const boardSlot: BoardSlot = {
        ...slot,
        timeText: parseDate(slot.start).time,
      };
      if (isMorning(slot.start)) board[0].slots.push(boardSlot);
      else board[1].slots.push(boardSlot);
    }
    return board;
  }, [selectedStartTime, availabilitySlots]);

  const selectSlot = useCallback(
    (selectedSlot: BoardSlot) => {
      setSelectedSlot(selectedSlot);
      const slotSpecialityId = selectedSlot?.specialityId;
      if (setSubmissionURLRef && slotSpecialityId) setSubmissionURLRef(submitUrl(selectedSlot, slotSpecialityId));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathName, setSubmissionURLRef, workgroupId],
  );

  useEffect(() => {
    // Get first available slot from the board
    const firstSlot = board[0].slots[0] || board[1].slots[0];
    if (!firstSlot) return;

    // If we don't have a selected slot yet, select the first available slot
    if (!selectedSlot) {
      selectSlot(firstSlot);
      return;
    }
    // Check if the day has changed
    const isDayChanged = !areSameDayTimestamps(firstSlot.start, selectedSlot.start);
    if (isDayChanged) {
      selectSlot(firstSlot);
    }
  }, [board, hasAvailabilities, selectedSlot, selectSlot]);

  const onSubmit = () => {
    const slotSpecialityId = selectedSlot?.specialityId;
    if (!selectedSlot || !slotSpecialityId) return;

    if (onAddToCart) onAddToCart();
    router.push(submitUrl(selectedSlot, slotSpecialityId));
  };

  useEffect(() => {
    if (setExternalSlot && selectedSlot) {
      setExternalSlot(selectedSlot);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSlot]);

  const availableDatesTimestamps = availabilitySlots?.map(slot => slot.start) ?? [];

  useEffect(() => {
    updateCheckoutFormField('formStep', FormStep.Information);
  }, [updateCheckoutFormField]);

  useEffect(() => {
    if (selectedSlot && isTimeStampInSameDayOfLastAvailability(selectedSlot.start, availabilitySlots?.map(slot => slot.start) ?? [])) {
      loadAvailabilities(selectedSlot.start);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSlot]);

  useEffect(() => {
    if (!availabilitySlots.length) {
      setSelectedSlot(undefined);
      setUserSelectedStartTime(undefined);
    }
  }, [availabilitySlots]);

  return (
    <div className="flex flex-col gap-2 md:gap-4" data-cy={`'times-board-container`}>
      <Dates
        selected={selectedStartTime}
        onSelect={setUserSelectedStartTime}
        disabled={disabled || availableDatesTimestamps.length === 0}
        numberOfDays={numberOfDays}
        availableDatesTimestamps={availableDatesTimestamps}
        numberOfDaysNeededForPeriodSeparator={2}
      />

      <div>
        {isClientSide &&
          (loading ? (
            <div className="flex min-h-[9rem] items-center justify-center ">
              <ELLoader />
            </div>
          ) : hasAvailabilities ? (
            <div className="min-h-[9rem]">
              <Times board={board} onSelect={selectSlot} selected={selectedSlot} />
              {isInCard && (
                <div className="mt-14 grid grid-cols-1 items-center gap-y-4 md:mt-4 lg:gap-x-6 xl:grid-cols-[155px_auto] xl:gap-y-0 xl:pb-10">
                  <ELButton
                    aria-label="Prenota online"
                    label="Prenota online"
                    onClick={onSubmit}
                    data-cy="times-board-submission"
                    variant="filled"
                    color="primary"
                    fullWidth
                    size="medium"
                  />
                  {!apiHeaders.Authorization && (
                    <div className="text-center 2xl:text-left">
                      <PhoneNumberCTA />
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="flex min-h-[9rem] items-center justify-center ">
              <h4 className="text-center text-sm font-medium text-gray-700 lg:text-base">
                {isInvalidSelection
                  ? 'Seleziona la specializzazione e la prestazione per vedere gli orari disponibili'
                  : 'Al momento non ci sono orari disponibili'}
              </h4>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AvailabilityContainer;
