import { AvailabilitySlotsForSpecialityNoAuthDocument, AvailabilitySlotsForSpecialityNoAuthQueryResult } from '@/graphql/types';
import client from '@/utils/apolloClient';
import { today } from '@/utils/datetime';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import AvailabilityMainContainer, { BoardSlot } from './availabilityContainer';

type AvailabilityProps = {
  workgroupId?: string | null;
  selectedSpeciality?: { serviceId?: string; doctorId?: string };
  isInCard?: boolean;
  onAddToCart?: () => void;
  setSubmissionURLRef?: (s: string) => void;
  numberOfDays: number;
  setExternalSlot?: (s: BoardSlot) => void;
  setExternalLoading?: Dispatch<SetStateAction<boolean>>;
};

const Availability = ({
  workgroupId,
  selectedSpeciality,
  isInCard,
  onAddToCart,
  setSubmissionURLRef,
  numberOfDays,
  setExternalSlot,
  setExternalLoading,
}: AvailabilityProps) => {
  const [date, setDate] = useState<number>(today());
  const [isLoading, setIsLoading] = useState(false);
  const [availabilitySlots, setAvailabilitySlots] = useState<
    AvailabilitySlotsForSpecialityNoAuthQueryResult['availabilitySlotsForSpecialityNoAuth']
  >([]);
  const [currentSpecialitySelected, setCurrentSpecialitySelected] = useState<undefined | null | { serviceId?: string; doctorId?: string }>(
    selectedSpeciality,
  );

  const loadAvailabilities = async (startDate: number) => {
    if (workgroupId && startDate && currentSpecialitySelected?.serviceId && currentSpecialitySelected?.doctorId) {
      setIsLoading(true);
      setExternalLoading?.(true);

      try {
        const { data } = await client.query({
          query: AvailabilitySlotsForSpecialityNoAuthDocument,
          variables: {
            startDate: startDate,
            specialityId: currentSpecialitySelected.serviceId,
            doctorId: currentSpecialitySelected.doctorId,
            workgroupId: workgroupId,
            days: 30,
          },
          fetchPolicy: 'no-cache',
        });
        setAvailabilitySlots([...availabilitySlots, ...(data?.availabilitySlotsForSpecialityNoAuth ?? [])]);
      } catch (e) {
        console.error(e);
        setAvailabilitySlots([]);
      } finally {
        setIsLoading(false);
        setExternalLoading?.(false);
      }
    }
  };

  useEffect(() => {
    loadAvailabilities(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpecialitySelected]);

  useEffect(() => {
    if (
      selectedSpeciality?.doctorId !== currentSpecialitySelected?.doctorId ||
      selectedSpeciality?.serviceId !== currentSpecialitySelected?.serviceId
    ) {
      setAvailabilitySlots([]);
      setCurrentSpecialitySelected(selectedSpeciality);
      setDate(today());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpeciality]);

  return (
    <div className="flex h-full flex-col gap-4" data-cy="availability-index">
      <AvailabilityMainContainer
        availabilitySlots={availabilitySlots}
        workgroupId={workgroupId}
        loading={isLoading}
        disabled={!selectedSpeciality}
        isInCard={isInCard}
        onAddToCart={onAddToCart}
        setSubmissionURLRef={setSubmissionURLRef}
        numberOfDays={numberOfDays}
        loadAvailabilities={loadAvailabilities}
        setExternalSlot={setExternalSlot}
      />
    </div>
  );
};

export default Availability;
