import { useEffect, useState } from 'react';
import clsx from 'clsx/lite';
import { Board, BoardSlot } from '.';
import { areSameTimestamps } from '@/utils/datetime';

type TimesProps = {
  board: Board;
  selected: BoardSlot | undefined;
  onSelect: (value: BoardSlot) => void;
};

const Times = ({ board, selected, onSelect: select }: TimesProps) => {
  const [showMore, handleShowMore] = useState({} as { [k: string]: boolean });

  useEffect(() => {
    const showMoreStates = {} as { [k: string]: boolean };
    for (const row of board) {
      if (!row?.label) {
        continue;
      }
      showMoreStates[row.label] = false;
    }
    handleShowMore(showMoreStates);
  }, [board]);

  return (
    <div data-cy="availability-times">
      <div className="flex flex-col gap-[2px] md:gap-2">
        {board.map(({ label, slots }) => {
          if (!slots?.length) return null;
          return (
            <div key={label}>
              <h3 className="lg:font-medi text-xs font-semibold text-gray-700">{label}</h3>
              {slots.length > 5 && !showMore[label] ? (
                <ul className="flex gap-4 overflow-x-auto py-2 lg:flex-wrap lg:gap-3 lg:overflow-x-hidden">
                  {slots.slice(0, 4).map((slot, index) => (
                    <button
                      aria-label={slot.timeText}
                      onClick={() => select(slot)}
                      data-id="add_chart"
                      key={`${slot.id}_${slot.timeText}_${index}`}
                      data-cy={`button-add-chart`}
                    >
                      <li
                        className={clsx(
                          'rounded-6xl border p-2 px-3 text-sm font-semibold',
                          selected?.start && areSameTimestamps(selected.start, slot.start)
                            ? 'border-primary-active bg-primary-light-active text-primary-active'
                            : 'border-primary bg-neutral-surface text-primary hover:border-primary-hover hover:bg-primary-light-hover hover:text-primary-hover active:border-primary-pressed active:bg-primary-light-pressed active:text-primary-pressed',
                        )}
                      >
                        {slot.timeText}
                      </li>
                    </button>
                  ))}
                  <li>
                    <button
                      aria-label="Mostra di più"
                      onClick={() => handleShowMore({ ...showMore, [label]: true })}
                      data-id="add_chart"
                      className={clsx(
                        'rounded-6xl border border-primary bg-neutral-surface p-2 px-3 text-sm font-semibold text-primary hover:border-primary-hover hover:bg-primary-light-hover hover:text-primary-hover active:border-primary-pressed active:bg-primary-light-pressed active:text-primary-pressed',
                      )}
                    >
                      ...
                    </button>
                  </li>
                </ul>
              ) : (
                <ul className="flex gap-4 overflow-x-auto py-2 lg:flex-wrap lg:gap-3 lg:overflow-x-hidden">
                  {slots.map((slot, index) => (
                    <button
                      aria-label={slot.timeText}
                      onClick={() => select(slot)}
                      data-id="add_chart"
                      key={`${slot.id}_${slot.timeText}_${index}`}
                      data-cy={`button-add-chart`}
                    >
                      <li
                        className={clsx(
                          'rounded-6xl border p-2 px-3 text-sm font-semibold',
                          selected?.start && areSameTimestamps(selected.start, slot.start)
                            ? 'border-primary-active bg-primary-light-active text-primary-active'
                            : 'border-primary bg-neutral-surface text-primary hover:border-primary-hover hover:bg-primary-light-hover hover:text-primary-hover active:border-primary-pressed active:bg-primary-light-pressed active:text-primary-pressed',
                        )}
                      >
                        {slot.timeText}
                      </li>
                    </button>
                  ))}
                </ul>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Times;
