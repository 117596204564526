import React, { useMemo, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { addDays, areSameDayTimestamps, parseDate } from '@/utils/datetime';
import clsx from 'clsx/lite';
import DatesList from './dates/datesList';

type DatesProps = {
  selected?: number;
  setFirstDate?: number;
  onSelect?: (selected: number) => void;
  disabled?: boolean;
  numberOfDays: number;
  availableDatesTimestamps: number[];
  numberOfDaysNeededForPeriodSeparator: number;
};

const Dates = ({
  selected,
  onSelect,
  disabled = false,
  numberOfDays,
  availableDatesTimestamps,
  numberOfDaysNeededForPeriodSeparator,
}: DatesProps) => {
  const days = useMemo(() => {
    const map = new Map<string, number>();
    for (const timestamp of availableDatesTimestamps) {
      const { weekday, day, monthShort } = parseDate(timestamp);
      const key = Object.values({ weekday, day, month: monthShort }).join('');
      if (!map.has(key)) map.set(key, timestamp);
    }
    return Array.from(map.values());
  }, [availableDatesTimestamps]);
  const [daysBeforeSelected, setDaysBeforeSelected] = useState(0);

  const selectedDayIndex = useMemo(() => {
    return selected === undefined ? 0 : days.findIndex(date => areSameDayTimestamps(date, selected));
  }, [days, selected]);

  const datesList = useMemo(
    () => createDatesList({ selectedDayIndex, days, numberOfDays, disabled, daysBeforeSelected }),
    [selectedDayIndex, numberOfDays, days, disabled, daysBeforeSelected],
  );

  const select = (date: number) => {
    if (onSelect) onSelect(date);
  };

  const onPreviousClick = () => {
    select(days[selectedDayIndex - 1]);
    if (daysBeforeSelected > 0) {
      setDaysBeforeSelected(daysBeforeSelected - 1);
    }
  };

  const onNextClick = () => {
    if (daysBeforeSelected < numberOfDays - 1) {
      setDaysBeforeSelected(daysBeforeSelected + 1);
    }
    select(days[selectedDayIndex + 1]);
  };

  return (
    <div data-cy="availability-dates">
      <div
        className={clsx(
          'flex items-center justify-between rounded-6xl p-1 md:px-2 md:py-1 ',
          disabled ? 'bg-neutral-inactive text-neutral-inactive' : 'bg-neutral-surface-raised text-primary',
        )}
      >
        <button
          aria-label="precedente"
          className="relative h-4 w-4 flex-shrink-0"
          onClick={onPreviousClick}
          disabled={selectedDayIndex === 0 || disabled}
        >
          <ChevronLeftIcon className={clsx('stroke-2', selectedDayIndex === 0 || disabled ? 'text-neutral-inactive' : 'text-primary')} />
        </button>
        <DatesList
          dates={datesList}
          selected={selected}
          disabled={disabled}
          onSelect={select}
          numberOfDaysForPeriodSeparator={numberOfDaysNeededForPeriodSeparator}
        />
        <button
          aria-label="successivo"
          className="relative h-4 w-4 flex-shrink-0"
          onClick={onNextClick}
          disabled={disabled || selectedDayIndex === days.length - 1}
        >
          <ChevronRightIcon
            className={clsx('stroke-2', disabled || selectedDayIndex === days.length - 1 ? 'text-neutral-inactive' : 'text-primary')}
          />
        </button>
      </div>
    </div>
  );
};

export default Dates;

export const createDatesList = ({
  disabled,
  numberOfDays,
  days,
  selectedDayIndex,
  daysBeforeSelected,
}: {
  disabled: boolean;
  numberOfDays: number;
  days: number[];
  selectedDayIndex: number;
  daysBeforeSelected: number;
}) => {
  if (disabled) return Array.from({ length: numberOfDays }, (_, i) => addDays(i));

  if (days.length === 0) return [];

  let startingIndex = Math.min(selectedDayIndex - daysBeforeSelected, days.length - numberOfDays);
  startingIndex = Math.max(startingIndex, 0);

  return days.slice(startingIndex, startingIndex + numberOfDays).filter(date => date !== undefined);
};
