import { ONE_DAY_IN_MILLISECONDS } from '@/utils/dates';
import DateButton from './dateButton';
import React from 'react';

type DatesListProps = {
  dates: number[];
  selected: number | undefined;
  onSelect: (selected: number) => void;
  numberOfDaysForPeriodSeparator: number;
  disabled?: boolean;
};
const DatesList = ({ dates, selected, onSelect, numberOfDaysForPeriodSeparator, disabled }: DatesListProps) => {
  return (
    <>
      {dates.map((date, i, dates) => {
        const nextDate = dates[i + 1];
        const isPeriodSeparatorNeeded =
          nextDate && numberOfDaysForPeriodSeparator && nextDate - date >= ONE_DAY_IN_MILLISECONDS * numberOfDaysForPeriodSeparator;

        return (
          <React.Fragment key={date}>
            <DateButton disabled={disabled} date={date} selected={!!selected && selected === date} onClick={() => onSelect(date)} />
            {isPeriodSeparatorNeeded && <div className="h-[5px] w-9 rounded-full bg-neutral-inactive" />}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default DatesList;
