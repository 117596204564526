import { isToday, isTomorrow, parseDate } from '@/utils/datetime';
import clsx from 'clsx/lite';
import { MouseEventHandler } from 'react';

type DateButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  date: number;
  disabled?: boolean;
  selected?: boolean;
};
const DateButton = ({ onClick, date, disabled, selected }: DateButtonProps) => {
  const { weekday, day, monthShort } = parseDate(date);

  return (
    <button
      aria-label={`${weekday} ${day} ${monthShort}`}
      className={clsx(
        'block flex-shrink-0 flex-grow-0 rounded-[2rem] px-3 py-[2px]',
        selected && !disabled && 'border border-primary-active text-primary-active',
        !selected &&
          !disabled &&
          'text-primary hover:bg-primary-light-hover hover:text-primary-hover active:bg-primary-light-pressed active:text-primary-pressed',
        disabled && 'text-neutral-inactive',
      )}
      key={`${weekday}-${day}-${monthShort}`}
      onClick={onClick}
      disabled={disabled}
      data-cy="dates-list-button"
    >
      <div className="text-[9px] font-semibold leading-[16px]">{weekday}</div>
      <div className="whitespace-nowrap text-sm font-semibold capitalize">
        {isToday(date) ? 'oggi' : isTomorrow(date) ? 'domani' : `${day} ${monthShort}`}
      </div>
    </button>
  );
};

export default DateButton;
